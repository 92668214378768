<template>
  <div
    v-if="notification.message"
    class="notification shadow rounded border border-primary"
    :class="
      notification.status == 'error'
        ? 'bg-danger'
        : notification.status == 'success'
        ? 'bg-success'
        : notification.status == 'warning'
        ? 'bg-warning'
        : ''
    "
  >
    <div class="col m-0 p-0 d-flex align-items-center">
      <div class="icon col-auto">
        <span v-if="notification.status == 'warning'">
          <i class="bi bi-info-square-fill fs-3 text-white"></i>
        </span>
        <span v-if="notification.status == 'error'">
          <i class="bi bi-exclamation-triangle fs-3 text-white"></i>
        </span>
        <span v-if="notification.status == 'success'">
          <i class="bi bi-check2-square fs-3 text-white"></i>
        </span>
      </div>
      <div
        class="text col"
        :class="
          notification.status == 'success'
            ? 'text-white'
            : notification.status == 'error'
            ? 'text-white'
            : notification.status == 'info'
            ? 'text-white'
            : notification.status == 'warning'
            ? 'text-white'
            : ''
        "
      >
        <span class="fw-bold"> {{ notification.message }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("notification", {
      notification: "getNotification",
    }),
  },
};
</script>

<style scoped>
.notification {
  height: 40px !important;
  position: fixed;
  top: auto;
  bottom: 20px;
  width: 98%;
  z-index: 3;
}
@media screen and (max-width: 720px) {
  .notification {
    height: 50px !important;
    position: fixed;
    top: auto;
    bottom: 20px;
    width: 92%;
    z-index: 3;
  }
}

.notification .icon {
  height: 40px !important;
  margin-top: auto;
  display: flex;
  align-items: center;
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
}

.notification .text {
  height: 40px !important;
  margin-top: auto;
  display: flex;
  align-items: center;
  margin: 5px;
  padding-left: 8px;
  border-radius: 5px;
}

.success {
  border: 1px solid #166806;
  background: #166806;
}

.info {
  border: 1px solid white;
}

.danger {
  border: 1px solid white;
}

.warning {
  border: 1px solid white;
}
</style>
