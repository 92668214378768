<template>
  <div>
    <div class="card-cart overflow-auto rounded">
      <div class="">
        <button
          class="btn btn-secondary d-flex justify-content-center align-items-center w-100"
          @click="refresh()"
        >
          <i class="bi bi-arrow-clockwise fs-4"></i>
          <span class="m-2"> Actualiser </span>
        </button>
      </div>
      <div v-if="!items">
        <span> Panier Vide </span>
      </div>
      <div v-for="(item, index) in items" :key="index++">
        <button
          class="w-100 btn text-start border-0 border-bottom border-dark rounded-0 mt-2"
          @dblclick="deleteItem(item.id)"
        >
          <div class="w-100 text-uppercase">
            <span class="fw-bold text-warning">
              {{ index }}
            </span>
            {{ item.item.fullName }}
          </div>

          <div class="d-flex justify-content-between">
            <span class="fw-bold">
              {{ parseFloat(item.quantity).toFixed(3) }} KG
            </span>

            <span class="fw-bold">
              {{ parseFloat(item.priceTTC).toFixed(2) }} DH
            </span>

            <span class="fw-bold">
              {{ parseFloat(item.totalPriceTTC).toFixed(2) }} DH
            </span>
          </div>
        </button>
      </div>
    </div>
    <br />
    <div class="cart-footer w-100">
      <div class="p-2">
        <div class="d-flex justify-content-between align-items-center">
          <span>
            {{ customer.fullName }}
          </span>
          <span>
            {{ customer.society }}
          </span>
        </div>
        <hr />
      </div>
      <div class="d-flex justify-content-between align-items-center px-2">
        <span class="fw-bold"> {{ numberItems }} </span>

        <span class="fw-bold"> {{ totalPriceTTCItems }} DH </span>

        <span class="fw-bold"> {{ totalQuantityItems }} KG</span>
        <span v-show="customer">
          <button
            @click="save({ customer_reference: customer.reference })"
            class="btn border-0"
          >
            <i
              class="bi bi-arrow-right-circle-fill text-white"
              :style="{
                fontSize: '34px',
              }"
            ></i>
          </button>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  setup() {},
  mounted() {
    this.$store.dispatch("cart/getItems");
  },
  computed: {
    ...mapGetters("cart", {
      items: "getItems",
      numberItems: "getNumberItems",
      totalQuantityItems: "getTotalQuantityItems",
      totalPriceTTCItems: "getTotalPriceTTCItems",
    }),
    ...mapGetters("customer", {
      customer: "getCustomer",
    }),
  },
  methods: {
    async refresh() {
      await this.$store.dispatch("cart/getItems");
    },
    async deleteItem(item_reference) {
      await this.$store.dispatch("cart/destroyItem", item_reference);
    },
    async save(data) {
      await (data.date = new Date(
        new Date().setFullYear(new Date().getFullYear())
      )
        .toLocaleString("en-CA")
        .split(",")[0]);
      await this.$store.dispatch("sale/store", data);
      await this.$store.dispatch("cart/getItems");
      await this.$router.push({ name: "print" });
    },
  },
};
</script>
<style scoped>
.card-cart {
  height: 550px;
  width: 100%;
  /* border: 1px solid #000; */
  /* position: fixed; */
  top: 0;
  left: 0;
  z-index: 1;
  margin-top: 10px;
  /* padding-top: 70px; */
  box-shadow: rgb(204, 219, 232) 0px 2px 4px 0px inset,
    rgb(204, 219, 232) 0px 2px 16px 0px inset;
}

.cart-footer {
  /* margin-top: auto; */
  /* position: fixed; */
  /* width: 250px; */
  /* bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px; */
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 32px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  background: #5a425c;
  border-radius: 6px;
  color: #ffffff;
}
@media screen and (max-width: 700px) {
  .card-cart {
    height: auto;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    margin-top: 10px;
    box-shadow: rgb(204, 219, 232) 0px 2px 4px 0px inset,
      rgb(204, 219, 232) 0px 2px 16px 0px inset;
  }
}
</style>
