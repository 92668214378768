<template>
  <div class="container">
    <div
      class="row justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-center px-5 pt-5"
    >
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'login' })"
          class="btn btn-success d-flex justify-content-center align-items-center"
        >
          <i class="bi bi-caret-right-square fs-4"></i>
          <span class="m-2"> Se Connecter </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
