<template>
  <div class="card-infos">
    <h2>Infos</h2>
    <div class="row justify-content-between fw-bold">
      <span class="col-auto">
        {{ customer.fullName }}
      </span>
      <span class="col-auto">
        {{ customer.phone }}
      </span>
    </div>
    {{ item.fullName }}
    <div
      class="row justify-content-between align-items-center"
      v-if="!customer"
    >
      <span
        class="col-auto d-flex justify-content-between align-items-center text-warning fw-bold"
      >
        <i class="bi bi-exclamation-triangle-fill fs-2 ml-2"></i>

        Veuillez choisir le client
      </span>
    </div>
    <!-- <div v-if="customer.type != ''">
      {{ customer.type.fullName }}
    </div> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  mounted() {},
  computed: {
    ...mapGetters("customer", {
      customer: "getCustomer",
    }),

    ...mapGetters("cart", {
      item: "getItem",
    }),
  },
};
</script>
<style scoped>
.card-infos {
  background: #dadada;
  height: 290px;
  padding: 10px;
}

@media screen and (max-width: 700px) {
  .card-infos {
  background: #dadada;
  height: auto;
  padding: 10px;
}
}
</style>
