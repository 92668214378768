<template>
  <div class="">
    <div class="card-items d-flex align-items-start flex-wrap overflow-auto">
      <div
        class="col-xl-4 col-lg-4 col-md-4 col-6 card-item"
        v-for="(product, index) in products"
        :key="index++"
      >
        <button class="btn" @click="addToCart(product)">
          <span class="item-name text-uppercase fw-bold">
            {{ product.fullName }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  setup() {},
  mounted() {
    this.$store.dispatch("product/getAll");
  },
  computed: {
    ...mapGetters("product", {
      products: "getAll",
    }),

    ...mapGetters("customer", {
      customer: "getCustomer",
    }),
  },
  methods: {
    addToCart(item) {
      console.log(item);
      this.$store.dispatch("cart/addToCart", item);
    },
  },
};
</script>
<style scoped>
.card-items {
  height: auto;
  max-height: 590px;
  background: none;
  margin: 5px;
}
.card-item {
  /* height: 80px; */
  padding: 10px;
  /* margin: 20px; */
}

.card-item .btn {
  width: 100%;
  height: 100%;
  background: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;

    box-shadow: rgb(204, 219, 232) 0px 2px 4px 0px inset, rgb(204, 219, 232) 0px 2px 16px 0px inset;
}
.card-item .btn .item-name {
  /* box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px; */
  height: 110px;
  width: 100%;
  /* background: #fd8c8c; */
  padding: 5px;
  border-radius: 5px;
}
.card-item .btn .item-price {
  /* position: absolute; */
  width: 50%;
  /* background: #e24b1d; */
  margin-left: auto;
  margin-right: auto;
  margin-top: -45px;
  padding: 10px;
  color: #3d3475;
  border-radius: 5px;
  font-weight: bold;
  font-size: 25px;
  /* box-shadow: #e2e2e2 0px 7px 29px 0px; */
}
</style>
