import axios from "axios";
import constant from "../../constant";
import router from "../../router";
const api = constant.api + "";
const url = constant.url + "";
const state = () => ({
  authenticated: false,
  user: {},
});

const getters = {
  authenticated(state) {
    let user = localStorage.getItem("user-app");
    // let user = JSON.parse(localStorage.getItem("user-app"));
    if (user) {
      state.user = user;
      return true;
    } else {
      state.user = {};
      return false;
    }
    // return state.authenticated;
  },
  user: (state) => {
    let user = JSON.parse(localStorage.getItem("user-app-storekeeper"));
    if (user) {
      state.user = user;
      return state.user;
    } else {
      state.user = {};
      return state.user;
    }
  },

  getAuthData: (state) => {
    let data = JSON.parse(localStorage.getItem("token-app-storekeeper"));
    console.lof(data);
    if (data != null) {
      return data;
    } else {
      return null;
    }
  },
};

const actions = {
  async login({ commit }, data) {
    return axios.get(url + "sanctum/csrf-cookie").then((p) => {
      axios
        .post(api + "auth/login/storekeeper", data)
        .then((response) => {
          commit("setToken", response.data.token);
          commit("setUser", response.data.user);
          commit("setAuthentificated", true);
          if (response.data.status === "success") {
            router.push({ name: "layout" });
            setTimeout(window.location, 2000);
          }
        })
        .catch(({ response: { data } }) => {
          // commit("SET_USER", {});
          // commit("SET_AUTHENTICATED", false);
        });
    });
  },
  logout({ commit }) {
    commit("setAuthentificated", false);
    localStorage.removeItem("user-app-storekeeper");
    localStorage.removeItem("token-app-storekeeper");

    if (
      !localStorage.getItem("token-app-storekeeper") &&
      !localStorage.getItem("user-app-storekeeper")
    ) {
      router.push({ name: "login" });
    }
  },
};

const mutations = {
  setAuthentificated(state, value) {
    state.authenticated = value;
  },
  setUser(state, value) {
    state.user = value;
    console.log(value);
    localStorage.setItem("user-app-storekeeper", JSON.stringify(value));
    console.log(localStorage.getItem("user-app-storekeeper"));
  },
  setToken(state, value) {
    console.log(value);
    localStorage.setItem("token-app-storekeeper", JSON.stringify(value));
    console.log(localStorage.getItem("token-app-storekeeper"));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
