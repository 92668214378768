import axios from "axios";
import constant from "../../constant";
const api = constant.api + "sales";
const state = {
  cart: {},
  items: [],
  item: {},
  raw_material: {},
};

// getters
const getters = {
  getCart: (state) => {
    return state.cart;
  },
  getItems: (state) => {
    return state.items;
  },

  getItem: (state) => {
    return state.item;
  },

  getTotalPriceTTCItems: (state) =>
    state.items.reduce(
      (acc, val) => acc + parseFloat(val.quantity) * parseFloat(val.priceTTC),
      0
    ),

  getTotalQuantityItems: (state) =>
    state.items.reduce((acc, val) => acc + parseFloat(val.quantity), 0),
  getNumberItems: (state) => parseFloat(state.items.length),
};

// actions
const actions = {
  async setItem({ commit }, data) {
    commit("setItem", data);
  },

  async getAll({ commit }) {
    return await axios.get(api).then((response) => {
      commit("setAll", response.data);
    });
  },
  async getItems({ commit }) {
    return await axios.get(api + "_items").then((response) => {
      commit("setItems", response.data);
    });
  },

  async destroyItem({ commit, dispatch }, item_reference) {
    return await axios
      .delete(api + "_items/" + item_reference)
      .then((response) => {
        commit("setItems", response.data.data);

        let message = response.data.message;
        let status = response.data.status;
        dispatch("notification/store", { message, status }, { root: true });
      });
  },

  async addToCart({ commit }, data) {
    return await commit("setItem", data);
  },
};

// mutations
const mutations = {
  async setAll(state, data) {
    return (state.cart = data);
  },

  async setItem(state, data) {
    return (state.item = data);
  },

  async setItems(state, data) {
    return (state.items = data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
