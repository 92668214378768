const state = {
  notification: " ie ie 2r hhwoioiw hrw",
};

// getters
const getters = {
  getNotification: (state) => {
    return state.notification;
  },
};

// actions
const actions = {
  async store({ commit }, data) {
    await commit("setNotification", data);
    setTimeout(function () {
      commit("setNotification", "");
    }, 7000);
  },
};

// mutations
const mutations = {
  async setNotification(state, data) {
    return (state.notification = data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
