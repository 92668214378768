import axios from "axios";
import constant from "../../constant";
const api = constant.api + "sales";
const state = {
  cart: {},
  items: [],
  sales: [],
};

// getters
const getters = {
  getCart: (state) => {
    return state.cart;
  },
  getItems: (state) => {
    return state.items;
  },

  
};

// actions
const actions = {
  async storeItem({ commit, dispatch }, data) {
    return await axios.post(api + "_items", data).then((response) => {
      commit("setItems", response.data.data);
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { message, status }, { root: true });
      commit("cart/setItem", {}, { root: true });
    });
  },

  async store({ commit, dispatch }, data) {
    return await axios.post(api, data).then((response) => {
      commit("setSales", response.data.data);
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { message, status }, { root: true });
      if (status == "success") commit("setItems", []);
    });
  },

  async getSale({ commit }) {
    return await axios.get(api).then((response) => {
      commit("setAll", response.data);
    });
  },
  async getItems({ commit }) {
    return await axios.get(api + "_items").then((response) => {
      commit("cart/setItems", response.data, { root: true });
    });
  },

  async addToCart({ commit }, data) {
    return await commit("setItem", data);
  },
};

// mutations
const mutations = {
  async setAll(state, data) {
    return (state.cart = data);
  },

  async setItems(state, data) {
    return (state.items = data);
  },

  async setSales(state, data) {
    return (state.sales = data);
  },

  async setItem(state, data) {
    return (state.item = data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
