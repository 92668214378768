import axios from "axios";
import constant from "../../constant";
const api = constant.api + "items";
const state = {
  products: {},
};

// getters
const getters = {
  getAll: (state) => {
    return state.products;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    return await axios.get(api).then((response) => {
      commit("setAll", response.data);
    });
  },
  async getBySection({ commit }, section_reference) {
    return await axios
      .get(api + "/items/" + section_reference)
      .then((response) => {
        commit("setAll", response.data);
      });
  },
};

// mutations
const mutations = {
  async setAll(state, data) {
    console.log(data)
    return (state.products = data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
