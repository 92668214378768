<template>
  <div>
    <div class="col-12 p-0 m-0">
      <button
        class="btn btn-primary p-0 m-0 border-0 w-100"
        @click="
          display_customers == true
            ? (display_customers = false)
            : (display_customers = true)
        "
      >
        <span> Sélectionner un client </span>
        <span v-if="display_customers == false">
          <i class="bi bi-person-fill-down fs-1"></i>
        </span>
        <span v-else-if="display_customers == true">
          <i class="bi bi-person-fill-up fs-1"></i>
        </span>
      </button>
    </div>
    <div
      class="d-flex card-customers flex-wrap align-items-stretch overflow-auto"
    >
      <div
        v-show="display_customers == true"
        class="col-12 card-customer"
        v-for="(customer, index) in customers"
        :key="index++"
      >
        <button class="btn" @click="selectCustomer(customer.reference)">
          {{ customer.fullName }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      display_customers: false,
    };
  },
  mounted() {
    this.$store.dispatch("customer/getAll");
  },
  computed: {
    ...mapGetters("customer", {
      customers: "getAll",
      customer: "getCustomer",
    }),
  },
  methods: {
    selectCustomer(reference) {
      this.$store.dispatch("customer/show", reference);
      this.display_customers = false;
    },
  },
};
</script>

<style scoped>
.card-customers {
  max-height: 300px;
  /* width: 100%; */
  max-width: 300px;
  margin: 5px;
  position: absolute;
  background: #fff;
  /* border: 1px solid #000; */
  border-radius: 5px;
  z-index: 2;
}
.card-customer {
  padding: 10px;
  height: 80px;
}

.card-customer .btn {
  width: 100%;
  height: 100%;
  background: #8fd8ff;
}

@media screen and (max-width: 700px) {
  .card-customers {
    max-height: auto;
    max-width: 100%;
    margin: 5px;
    position: relative;
    background: #fff;
    /* border: 1px solid #000; */
    border-radius: 5px;
    z-index: 2;
  }
}
</style>
