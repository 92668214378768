var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.notification.message)?_c('div',{staticClass:"notification shadow rounded border border-primary",class:_vm.notification.status == 'error'
      ? 'bg-danger'
      : _vm.notification.status == 'success'
      ? 'bg-success'
      : _vm.notification.status == 'warning'
      ? 'bg-warning'
      : ''},[_c('div',{staticClass:"col m-0 p-0 d-flex align-items-center"},[_c('div',{staticClass:"icon col-auto"},[(_vm.notification.status == 'warning')?_c('span',[_c('i',{staticClass:"bi bi-info-square-fill fs-3 text-white"})]):_vm._e(),(_vm.notification.status == 'error')?_c('span',[_c('i',{staticClass:"bi bi-exclamation-triangle fs-3 text-white"})]):_vm._e(),(_vm.notification.status == 'success')?_c('span',[_c('i',{staticClass:"bi bi-check2-square fs-3 text-white"})]):_vm._e()]),_c('div',{staticClass:"text col",class:_vm.notification.status == 'success'
          ? 'text-white'
          : _vm.notification.status == 'error'
          ? 'text-white'
          : _vm.notification.status == 'info'
          ? 'text-white'
          : _vm.notification.status == 'warning'
          ? 'text-white'
          : ''},[_c('span',{staticClass:"fw-bold"},[_vm._v(" "+_vm._s(_vm.notification.message)+" ")])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }