import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

//modules
import auth from "./modules/auth";
import product from "./modules/product";
import category from "./modules/category";
import customer from "./modules/customer";
import cart from "./modules/cart";
import notification from "./modules/notification";
import sale from "./modules/sale";
import stock from "./modules/stock";
import storekeeper from "./modules/storekeeper";
export default new Vuex.Store({
  state: {
    customer_type: {},
    customer: {},
  },
  mutations: {},
  actions: {},
  modules: {
    auth,
    category,
    customer,
    notification,
    product,
    cart,
    sale,
    stock,
    storekeeper,
  },
});
