<template>
  <div class="container-fuild p-3">
    <div class="row d-flex align-items-center">
      <div class="col-xl col-lg col-md col-12 position-absoluteff">
        <notification-vue></notification-vue>
      </div>
      <!-- <div class="col">user : {{ user }}</div> -->
      <div class="col-xl-auto col-lg-auto col-md-auto col-6 text-center">
        <button
          @click="refersh()"
          class="btn btn-secondary d-flex justify-content-center align-items-center"
        >
          <i class="bi bi-x-octagon fs-4"></i>
          <span class="m-2"> Actualiser </span>
        </button>
      </div>

      <div class="col-xl-auto col-lg-auto col-md-auto col-6 text-center">
        <button
          @click="$router.push({ name: 'stocks' })"
          class="btn btn-warning d-flex justify-content-center align-items-center"
        >
          <i class="bi bi-box fs-4"></i>
          <span class="m-2"> Stocks </span>
        </button>
      </div>

      <div class="col-xl-auto col-lg-auto col-md-auto col-6 text-center">
        <button
          @click="logout()"
          class="btn btn-danger d-flex justify-content-center align-items-center"
        >
          <i class="bi bi-x-octagon fs-4"></i>
          <span class="m-2"> Éteindre </span>
        </button>
      </div>
    </div>
    <hr />
    <div class="row justify-content-between">
      <div
        class="col-xl-3 col-lg-3 col-md-3 col-12 order-xl-0 order-2 p-xl-0 p-2 sidebar"
      >
        <div class="">
          <customers-vue></customers-vue>
          <div>
            <cart-vue></cart-vue>
          </div>
        </div>
      </div>
      <div
        class="col-xl-6 col-lg-6 col-md-6 col-12 order-xl-1 order-0 p-xl-0 p-2 content"
      >
        <div class="">
          <div class="">
            <sections-vue id="sections"></sections-vue>
          </div>
          <div class="">
            <items-vue id="items"></items-vue>
          </div>
        </div>
      </div>
      <div
        class="col-xl-3 col-lg-3 col-md-3 col-12 row order-xl-2 order-1 leftbar p-2"
      >
        <div class="col-12 order-xl-1 order-2">
          <keyboard-vue class="p-2" id="keyboard"></keyboard-vue>
        </div>
        <div class="col-12 order-xl-2 order-1">
          <infos-vue class="p-2" id="infos"></infos-vue>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import KeyboardVue from "../cards/Keyboard.vue";
import SectionsVue from "../cards/Sections.vue";
import ItemsVue from "../cards/Items.vue";
import CustomersVue from "../cards/Customers.vue";
import InfosVue from "../cards/Infos.vue";
import CartVue from "../cards/Cart.vue";
import NotificationVue from "../cards/Notification.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    "keyboard-vue": KeyboardVue,
    "sections-vue": SectionsVue,
    "items-vue": ItemsVue,
    "customers-vue": CustomersVue,
    "infos-vue": InfosVue,
    "cart-vue": CartVue,
    "notification-vue": NotificationVue,
  },
  computed: {
    ...mapGetters("auth", {
      user: "user",
    }),
  },
  beforeMount() {
    this.$store.dispatch("product/getAll");
    this.$store.dispatch("category/getAll");
    this.$store.dispatch("customer/getAll");
    this.$store.dispatch("cart/getItems");
  },
  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout");
    },
    async refersh() {
      await this.$store.dispatch("product/getAll");
      await this.$store.dispatch("category/getAll");
      await this.$store.dispatch("customer/getAll");
      await this.$store.dispatch("cart/getItems");
      // this.$router.go(0);
    },
  },
};
</script>
<style scoped></style>
