import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./app.css";
import "bootstrap-icons/font/bootstrap-icons";

Vue.config.productionTip = false;

window.axios = require("axios");
// axios.defaults.baseURL = "http://localhost:8081";
// axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const token = localStorage.getItem("token-app-storekeeper");
axios.defaults.headers.common["Authorization"] =
  "Bearer " + String(token).replaceAll('"', "");

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
