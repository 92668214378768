<template>
  <div>
    <div
      class="card-sections d-flex flex-nowrap align-items-stretch overflow-auto"
    >
      <div
        class="col-auto card-section"
        v-for="(category, index) in categories"
        :key="index++"
      >
        <button class="btn" @click="getItems(category.reference)">
          <span class="text-uppercase fw-bold">
            {{ category.fullName }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  mounted() {
    this.$store.dispatch("category/getAll");
  },
  computed: {
    ...mapGetters("category", {
      categories: "getAll",
    }),
  },
  methods: {
    getItems(reference) {
      console.log(reference);
      this.$store.dispatch("category/getItems", reference);
    },
  },
};
</script>

<style scoped>
.card-sections {
  background: #ffffff;
  margin: 5px;
}
.card-section {
  height: 60px;
  /* padding: 10px;
  margin: 10px; */
  margin: 10px;
}

.card-section .btn {
  width: 100%;
  height: 100%;
  /* background: #9aa1ef; */
      box-shadow: rgb(245, 173, 173) 0px 2px 4px 0px inset, rgb(245, 173, 173) 0px 2px 16px 0px inset;

  /* color: #ffffff; */
}
</style>
