import axios from "axios";
import constant from "../../constant";
const api = constant.api + "customers";
const state = {
  customers: [],
  customer: {},
};

// getters
const getters = {
  getAll: (state) => {
    return state.customers;
  },
  getCustomer: (state) => {
    return state.customer;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    return await axios.get(api).then((response) => {
      commit("setAll", response.data);
    });
  },
  async show({ commit }, reference) {
    return await axios.get(api + "/" + reference).then((response) => {
      commit("setCustomer", response.data);
    });
  },
};

// mutations
const mutations = {
  async setAll(state, data) {
    return (state.customers = data);
  },
  async setCustomer(state, data) {
    return (state.customer = data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
