import axios from "axios";
import constant from "../../constant";
const api = constant.api + "sections";
const state = {
  categories: {},
};

// getters
const getters = {
  getAll: (state) => {
    return state.categories;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    return await axios.get(api).then((response) => {
      commit("setAll", response.data);
    });
  },
  async getItems({ commit },section_reference) {
    return await axios.get(api+'/items/'+section_reference).then((response) => {
      commit("product/setAll", response.data,{ root: true });
    });
  },
};

// mutations
const mutations = {
  async setAll(state, data) {
    return (state.categories = data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
