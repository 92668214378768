import axios from "axios";
import constant from "../../constant";
const api = constant.api + "storekeepers";
const state = {
  storekeepers: [],
  storekeeper: {},
};

// getters
const getters = {
  getAll: (state) => {
    return state.storekeepers;
  },

  getStorekeeper: (state) => {
    return state.storekeeper;
  },
};

// actions
const actions = {
  async setStorekeeper({ commit }, data) {
    commit("setStorekeeper", data);
  },

  async getAll({ commit }) {
    return await axios.get(api).then((response) => {
      commit("setAll", response.data);
    });
  },
  async getStorekeeper({ commit }, reference) {
    return await axios.get(api + reference).then((response) => {
      commit("setStorekeeper", response.data);
    });
  },
};

// mutations
const mutations = {
  async setAll(state, data) {
    return (state.storekeepers = data);
  },

  async setStorekeeper(state, data) {
    return (state.storekeeper = data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
