<template>
  <div>
    <div class="row card-keyboard-value">
      <div class="col d-flex justify-content-between align-items-center p-0">
        <span class="fw-bold fs-2 ps-4">
          {{ valueKeyboard }}
        </span>
        <div class="col-auto">
          <button
            v-show="valueKeyboard != ''"
            :disabled="item.reference ? false : true"
            class="btn border-0"
            @click="save(valueKeyboard, customer.reference, item.reference)"
          >
            <i class="bi bi-check-circle-fill text-white fs-1"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row fw-bold">
      <div class="col-3">
        <button
          @click="keyEvent('', '7')"
          class="btn btn-keyboard"
          :disabled="item.reference ? false : true"
        >
          7
        </button>
      </div>
      <div class="col-3">
        <button
          @click="keyEvent('', '8')"
          class="btn btn-keyboard"
          :disabled="item.reference ? false : true"
        >
          8
        </button>
      </div>
      <div class="col-3">
        <button
          @click="keyEvent('', '9')"
          class="btn btn-keyboard"
          :disabled="item.reference ? false : true"
        >
          9
        </button>
      </div>
      <div class="col-3">
        <button
          @click="keyEvent('', '0')"
          class="btn btn-keyboard"
          :disabled="item.reference ? false : true"
        >
          0
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <button
          @click="keyEvent('', '4')"
          class="btn btn-keyboard"
          :disabled="item.reference ? false : true"
        >
          4
        </button>
      </div>
      <div class="col-3">
        <button
          @click="keyEvent('', '5')"
          class="btn btn-keyboard"
          :disabled="item.reference ? false : true"
        >
          5
        </button>
      </div>
      <div class="col-3">
        <button
          @click="keyEvent('', '6')"
          class="btn btn-keyboard"
          :disabled="item.reference ? false : true"
        >
          6
        </button>
      </div>
      <div class="col-3">
        <button
          @click="keyEvent('', 'C')"
          class="btn btn-keyboard"
          :disabled="item.reference ? false : true"
        >
          C
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <button
          @click="keyEvent('1', '1')"
          class="btn btn-keyboard"
          :disabled="item.reference ? false : true"
        >
          1
        </button>
      </div>
      <div class="col-3">
        <button
          @click="keyEvent('2', '2')"
          class="btn btn-keyboard"
          :disabled="item.reference ? false : true"
        >
          2
        </button>
      </div>
      <div class="col-3">
        <button
          @click="keyEvent('3', '3')"
          class="btn btn-keyboard"
          :disabled="item.reference ? false : true"
        >
          3
        </button>
      </div>
      <div class="col-3">
        <button
          @click="keyEvent('.', '.')"
          class="btn btn-keyboard"
          :disabled="item.reference ? false : true"
        >
          .
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      valueKeyboard: "",
    };
  },
  methods: {
    keyEvent(key, value) {
      if (value == "C") {
        this.valueKeyboard = "";
        return;
      } else if (value == ".") {
        if (this.valueKeyboard.includes(".") == true) {
          return;
        } else if (this.valueKeyboard.includes(".") == false) {
          this.valueKeyboard = this.valueKeyboard + "" + value;
          return;
        }
      } else {
        this.valueKeyboard = this.valueKeyboard + "" + value;
      }
    },
    async save(quantity, customer_reference, item_reference) {
      await this.$store.dispatch("sale/storeItem", {
        quantity,
        customer_reference,
        item_reference,
        priceTTC: 0,
        priceHT: 0,
      });
      this.valueKeyboard = "";
      await this.$store.dispatch("cart/getItems");
    },
  },
  computed: {
    ...mapGetters("customer", {
      customer: "getCustomer",
    }),

    ...mapGetters("cart", {
      item: "getItem",
    }),
  },
  mounted() {
    // this.$store.dispatch("cart/getItem");
  },
};
</script>
<style scoped>
.btn-keyboard {
  width: 100%;
  background: #ff7c4f;
  margin: 20px 0px;
  padding: 10px;
  color: #ffffff;
  font-weight: bold;
  font-size: x-large;
}
.card-keyboard-value {
  height: 75px;
  background: #5a425c;
  border-radius: 6px;
  width: 99%;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
}

@media screen and (max-width: 700px) {
  .bi-check-circle-fill {
    font-size: 45px  !important;
  }
}
</style>
