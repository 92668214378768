<template>
  <div id="app">
    <!-- <button @click="$router.push({ name: 'login' })" class="btn btn-success">
      App
    </button> -->
    <!-- <layout-vue></layout-vue> -->
    <router-view />
  </div>
</template>

<script>
import LayoutVue from "./components/layouts/Layout.vue";
export default {
  components: {
    "layout-vue": LayoutVue,
  },
  beforeMount() {
    // this.$router.push({ name: "login" });
  },
};
</script>
<style></style>
