import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";
Vue.use(VueRouter);

const routes = [
  {
    path: "/layout",
    name: "layout",
    meta: {
      requiredAuth: true,
      title: "",
    },
    component: () => import("../components/layouts/Layout.vue"),
  },
  {
    path: "/stocks",
    name: "stocks",
    meta: {
      requiredAuth: true,
      title: "",
    },
    component: () => import("../components/layouts/StockLayout.vue"),
  },

  {
    path: "/print",
    name: "print",
    meta: {
      requiredAuth: true,
      title: "",
    },
    component: () => import("../components/layouts/Printer.vue"),
  },

  {
    path: "/",
    name: "home",

    component: Home,
  },
  {
    path: "/about",
    name: "About",
    meta: {
      requiredAuth: true,
      title: "",
    },
    component: () => import("../views/About.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/login/:reference/password",
    name: "login-password",
    meta: {
      requiredAuth: false,
      title: "",
    },
    component: () => import("../views/LoginPassword.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function guard(to, from, next, authData) {
  // if (to.meta && to.meta.requiredAuth == true) {
  //   if (authData != null && authData.reference != undefined) {
  //     console.log("-1-");
  //     return next();
  //   }
  //   return next({ name: "login" });
  // }
  // if (to.name == "login") {
  //   console.log("route login");
  //   return next();
  // }
  return next();
}

router.beforeEach((to, from, next) => {
  console.log("before");
  let authData = store.getters["auth/user"];
  // let authData = '';

  console.log("after");
  return guard(to, from, next, authData);
});

export default router;
